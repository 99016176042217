// Flex
.flex--1 {
  flex: 1;
}

.flex--0-0-10 {
  flex: 0 0 calc(100% - 110px);
}

.flex--0-0-32 {
  flex: 0 0 32%;
}

// Flex Direction
.flex-direction--column {
  flex-direction: column;
}

.flex-direction--row {
  flex-direction: row;
}

// Flex Basis
.flex-basis--33 {
  flex-basis: calc(33.3% - 1px);
}

.flex-basis--33-minus-16px {
  flex-basis: calc(33.3% - 16px);
}

.flex-basis--24 {
  flex-basis: calc(24% - 15px);
}

// Flex Wrap
.flex-wrap--wrap {
  flex-wrap: wrap;
}
