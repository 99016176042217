.font-weight--400 {
  font-weight: 400;
}

.font-weight--500 {
  font-weight: 500;
}

.font-weight--600 {
  font-weight: 600;
}

.font-weight--700 {
  font-weight: 700;
}
