// Padding on all sides
.padding--0px {
  padding: 0px;
}

.padding--5px {
  padding: 5px;
}

.padding--10px {
  padding: 10px;
}

.padding--16px {
  padding: 16px;
}

.padding--20px {
  padding: 20px;
}

.padding--24px {
  padding: 24px;
}

.padding--26px {
  padding: 26px;
}

.padding--32px {
  padding: 32px;
}

.padding--100px {
  padding: 100px;
}

.padding--24px-24px-24px-0px {
  padding: 24px 24px 24px 0px;
}
.padding--0px-20px-0px-0px {
  padding: 0px 20px 0px 0px;
}

.padding--0px-0px-20px-0px {
  padding: 0px 0px 20px 0px;
}

// Padding Block Inline

.padding--2px-6px {
  padding: 2px 6px;
}

.padding--4px-12px {
  padding: 4px 12px;
}

.padding--8px-16px {
  padding: 8px 16px;
}

.padding--10px-14px {
  padding: 10px 14px;
}

.padding--10px-16px {
  padding: 10px 16px;
}

.padding--12px-24px {
  padding: 12px 24px;
}

.padding--14px-15px {
  padding: 14px 15px;
}

.padding--24px-32px {
  padding: 24px 32px;
}

.padding--48px-24px {
  padding: 48px 24px;
}

.padding--0-5 {
  padding: 0 5%;
}

// Padding Block
.padding-block--10px {
  padding-block: 10px;
}

.padding-block--24px {
  padding-block: 24px;
}

.padding-block--32px {
  padding-block: 32px;
}

.padding-block--64px {
  padding-block: 64px;
}

// Padding Inline
.padding-inline--16px {
  padding-inline: 16px;
}

.padding-inline--20px {
  padding-inline: 20px;
}

.padding-inline--96px {
  padding-inline: 96px;
}

// Padding Left
.padding-left--12px {
  padding-left: 12px;
}

.padding-left--16px {
  padding-left: 16px;
}

// Padding Top
.padding-top--24px {
  padding-top: 24px;
}

// Padding Bottom
.padding-bottom--96px {
  padding-bottom: 96px;
}
.padding-bottom--50px {
  padding-bottom: 50px;
}
