.position--relative {
  position: relative;
}

.position--absolute {
  position: absolute;
}

.position--sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

// Top
.top--0 {
  top: 0;
}

.top--100-minus-120px {
  top: calc(100vh - 120px) ;
}

// Left
.left--32px {
  left: 32px;
}
