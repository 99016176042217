@import "color-definition.scss";

// Color
.color--white {
  color: $white;
}

.color--gray {
  color: $s-gray;
}

.color--moss {
  color: $s-moss;
}

.color--success--700 {
  color: $success--700;
}

.color--text-primary-900 {
  color: $text-primary-900;
}

.color--text-secondary-700 {
  color: $text-secondary-700;
}

.color--utility-gray-400 {
  color: $utility-gray-400;
}

.color--header {
  color: $header-color;
}

.color--red {
  color: $profile-bg;
}

.color--error {
  color: $s-error;
}

.color--purple {
  color: $fg-brand-primary;
}

// Placeholder Color
.color--gray-placeholder::placeholder {
  color: $s-gray-placeholder;
}

// Border Color
.border-color--primary {
  border-color: $border-primary;
}

.border-color--success--200 {
  border-color: $success--200;
}

.border-color--secondary {
  border-color: $border-secondary;
}

.border-color--moss-500 {
  border-color: $moss-500;
}

// Background Color
.background--white {
  background: $white;
}

.background--success {
  background-color: $accepted;
}

.background--red {
  background: $profile-bg;
}

.background--bg-secondary-alt {
  background-color: $s-bg-secondary-alt;
}

.background--utility-gray-400 {
  background: $utility-gray-400;
}

.background--moss {
  background: $moss-500;
}

// Hover Color
.color--moss\:hover:hover {
  color: $s-moss;
}

.background-color--bg-quaternary\:hover:hover {
  background-color: $bg-quaternary;
}

.background--purple {
  background: $fg-brand-primary;
}

.background--inactive {
  background: $border-primary;
}
