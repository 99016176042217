.font--regular-400-10px-16px {
  font: 400 12px/16px "Inter", sans-serif;
}

.font--regular-400-14px-20px {
  font: 400 14px/20px "Inter", sans-serif;
}

.font--regular-500-14px-20px {
  font: 500 14px/20px "Inter", sans-serif;
}

.font--regular-400-16px-24px {
  font: 400 16px/24px "Inter", sans-serif;
}

.font--regular-400-16px-24px-italic {
  font: italic 400 16px/24px "Inter", sans-serif;
}

.font--regular-500-18px-28px-italic {
  font: italic 500 18px/28px "Inter", sans-serif;
}

.font--medium-500-12px-18px {
  font: 500 12px/18px "Inter", sans-serif;
}

.font--medium-500-14px-20px {
  font: 500 14px/20px "Inter", sans-serif;
}

.font-semibold-400-16px-20px {
  font: 400 16px/20px "Inter", sans-serif;
}

.font-semibold-600-14px-20px {
  font: 600 14px/20px "Inter", sans-serif;
}

.font--semibold-600-16px-24px {
  font: 600 16px/24px "Inter", sans-serif;
}

.font--semibold-700-14px-20px {
  font: 700 14px/20px "Inter", sans-serif;
}

.font--semibold-700-16px-24px {
  font: 700 16px/24px "Inter", sans-serif;
}

.font--semibold-600-18px-28px {
  font: 600 18px/28px "Inter", sans-serif;
}

.font--semibold-600-20px-32px {
  font: 600 20px/32px "Inter", sans-serif;
}

.font--semibold-600-24px-32px {
  font: 600 24px/32px "Inter", sans-serif;
}

.font--semibold-600-30px-38px {
  font: 600 30px/38px "Inter", sans-serif;
}

.font--semibold-600-36px-44px {
  font: 600 36px/44px "Inter", sans-serif;
}

.font--semibold-400-36px-60px {
  font: 400 36px/60px "Inter", sans-serif;
}

.font--semibold-700-18px-28px {
  font: 700 18px/28px "Inter", sans-serif;
}