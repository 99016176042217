.font-size--10px {
  font-size: 10px;
}

.font-size--12px {
  font-size: 12px;
}

.font-size--14px {
  font-size: 14px;
}

.font-size--16px {
  font-size: 16px;
}

.font-size--20px {
  font-size: 20px;
}

.font-size--24px {
  font-size: 24px;
}

.font-size--28px {
  font-size: 28px;
}

.font-size--30px {
  font-size: 30px;
}

.font-size--36px {
  font-size: 36px;
}

.font-size--38px {
  font-size: 38px;
}
