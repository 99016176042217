// Width percentage
.width--25 {
  width: 25vw;
}

.width--50 {
  width: 50vw;
}

.width--30 {
  width: 30%;
}

.width--100 {
  width: 100%;
}

// Width in pixels
.width--44px {
  width: 44px;
}

.width--48px {
  width: 48px;
}

.width--64px {
  width: 64px;
}

.width--82px {
  width: 82px;
}

.width--96px {
  width: 96px;
}

.width--100px {
  width: 100px;
}

.width--120px {
  width: 120px;
}

.width--150px {
  width: 150px;
}

.width--164px {
  width: 164px;
}

.width--172px {
  width: 172px;
}

.width--200px {
  width: 200px;
}

.width--220px {
  width: 220px;
}

.width--370px {
  width: 370px;
}

.width--568px {
  width: 568px;
}

.width--640px {
  width: 640px;
}

.width--700px {
  width: 700px;
}

.width--1000px {
  width: 1000px;
}

// Max width
.max-width--1000px {
  max-width: 1000px;
}

.min-width--1150px {
  min-width: 1150px;
}

.max-width--1200px {
  max-width: 1200px;
}

// Dynamic width
.width--100-minus-28px {
  width: calc(100% - 28px);
}

.width--45-minus-24px {
  width: calc(45vw - 24px);
}

// Other width definitions
.width--webkit-fill-available {
  width: -webkit-fill-available;
}
