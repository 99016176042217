// Resize
.resize--none {
  resize: none;
}

.resize--both {
  resize: both;
}

.resize--horizontal {
  resize: horizontal;
}

.resize--vertical {
  resize: vertical;
}
