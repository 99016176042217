.overflow--hidden {
  overflow: hidden;
}

.overflow--visible {
  overflow: visible;
}

.overflow--scroll {
  overflow: scroll;
}

.overflow--auto {
  overflow: auto;
}

.overflow-y--auto {
  overflow-y: auto;
}
