.gap--6px {
  gap: 6px;
}

.gap--8px {
  gap: 8px;
}

.gap--10px {
  gap: 10px;
}

.gap--12px {
  gap: 12px;
}

.gap--16px {
  gap: 16px;
}

.gap--20px {
  gap: 20px;
}

.gap--22px {
  gap: 22px;
}

.gap--24px {
  gap: 24px;
}

.gap--32px {
  gap: 32px;
}

.gap--48px {
  gap: 48px;
}

.gap--64px {
  gap: 64px;
}

.gap--80px {
  gap: 80px;
}
