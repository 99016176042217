.height--auto {
  height: auto;
}

// Height in percentage
.height--100 {
  height: 100%;
}

.height--10vh {
  height: 10vh;
}

.height--20vh {
  height: 20vh;
}

.height--30vh {
  height: 30vh;
}

.height--35vh {
  height: 35vh;
}

.height--40vh {
  height: 40vh;
}

.height--50vh {
  height: 50vh;
}

.height--70vh {
  height: 70vh;
}

.height--90vh {
  height: 90vh;
}

.height--100vh {
  height: 100vh;
}

.height--100-minus-110px {
  height: calc(100vh - 110px);
}

// Height in pixels
.height--8px {
  height: 8px;
}

.height--20px {
  height: 20px;
}

.height--24px {
  height: 24px;
}

.height--28px {
  height: 28px;
}

.height--40px {
  height: 40px;
}

.height--44px {
  height: 44px;
}

.height--48px {
  height: 48px;
}

.height--70px {
  height: 70px;
}

.height--90px {
  height: 90px;
}

.height--94px {
  height: 94px;
}

.height--100px {
  height: 100px;
}

.height--120px {
  height: 120px;
}

.height--150px {
  height: 150px;
}

.height--168px {
  height: 168px;
}

.height--200px {
  height: 200px;
}

.height--250px {
  height: 250px;
}

.height--360px {
  height: 360px;
}

// Max Height
.max-height--240px {
  max-height: 240px;
}

.max-height--300px {
  max-height: 300px;
}

// Max Height percentage
.max-height--72vh {
  max-height: 72vh;
}

// Min Height
.min-height--120px {
  min-height: 120px;
}

.min-height--300px {
  min-height: 300px;
}

.height--100-minus-48px {
  height: calc(100vh - 48px);
}
