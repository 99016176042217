// Margin on all sides
.margin--0px {
  margin: 0;
}

.margin--4px {
  margin: 4px;
}

.margin--10px {
  margin: 10px;
}

.margin--0-auto {
  margin: 0 auto;
}

// Margin Inline
.margin-inline--96px {
  margin-inline: 96px;
}

// Margin Block
.margin-block--24px {
  margin-block: 24px;
}

.margin-block--80px {
  margin-block: 80px;
}

// Margin Top
.margin-top--32px {
  margin-top: 32px;
}

.margin-top--43px {
  margin-top: 43px;
}

.margin-top--48px {
  margin-top: 48px;
}

.margin-top--60px {
  margin-top: 60px;
}

.margin-top--minus-20px {
  margin-top: -20px;
}

// Margin Bottom
.margin-bottom--12px {
  margin-bottom: 12px;
}

.margin-bottom--15px {
  margin-bottom: 15px;
}

.margin-bottom--32px {
  margin-bottom: 32px;
}

.margin-bottom--48px {
  margin-bottom: 48px;
}

.margin-bottom--80px {
  margin-bottom: 80px;
}

// Margin Right
.margin-right--5px {
  margin-right: 5px;
}

.margin-right--12px {
  margin-right: 12px;
}

.margin-right--40px {
  margin-right: 40px;
}

.margin-right--88px {
  margin-right: 88px;
  }
