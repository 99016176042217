// Border Radius
.border-radius--6px {
  border-radius: 6px;
}

.border-radius--8px {
  border-radius: 8px;
}

.border-radius--10px {
  border-radius: 10px;
}

.border-radius--12px {
  border-radius: 12px;
}

.border-radius--16px {
  border-radius: 16px;
}

.border-radius--20px {
  border-radius: 20px;
}

.border-radius--24px {
  border-radius: 24px;
}

.border-radius--50px {
  border-radius: 50px;
}

.border-radius--9999px {
  border-radius: 9999px;
}

.border-radius--100 {
  border-radius: 100%;
}

// Border Style
.border-style--solid {
  border-style: solid;
}

.border-style--none {
  border-style: none;
}

.border-style--dotted {
  border-style: dotted;
}

.border-style--dashed {
  border-style: dashed;
}

// Border Width
.border-width--1px {
  border-width: 1px;
}

// Border Definitions
.border--1px-solid-border-primary {
  border: 1px solid $border-primary;
}

.border--1px-solid-border-secondary {
  border: 1px solid $border-secondary;
}

.border--2px-solid-moss {
  border: 2px solid $s-moss;
}

.border--4px-solid-border-primary {
  border: 4px solid $border-primary;
}

.border--1px-solid-border-moss {
  border: 1px solid $s-moss;
}

.border--1px-solid-border-purple {
  border: 1px solid $fg-brand-primary;
}

.border--3px-solid-border-purple {
  border: 3px solid $fg-brand-primary;
}

.border--3px-dashed-border-purple {
  border: 3px dashed $fg-brand-primary;
}

// Border none
.border--none {
  border: none;
}

// Border Left
.border-left--none {
  border-left: none;
}

.border-left--2px-utility-gray-400 {
  border-left: 2px solid $utility-gray-400;
}

// Border Right
.border-right--none {
  border-right: none;
}

.border-right--1px-solid-utility-gray-400 {
  border-right: 1px solid $utility-gray-400;
}

// Border Top
.border-top--none {
  border-top: none;
}

// Border Bottom
.border-bottom--1px {
  border-bottom: 1px;
}

.border-bottom--1px-solid-secondary {
  border-bottom: 1px solid $border-secondary;
}

.border-bottom--1px-solid-border-primary {
  border-bottom: 1px solid $border-primary;
}

.border-bottom--3px-solid-border-moss {
  border-bottom: 3px solid $s-moss;
}

// Border Top Left Rigth
.border--top-left-right-none {
  border-top: none;
  border-left: none;
  border-right: none;
}

.border--4px-solid-moss {
  border: 4px solid $s-moss;
}

.border--2px-solid-border-moss {
  border: 2px solid $s-moss;
}