/* Color */
$white: #fff;
$black: #000000;
$s-gray: #475467;
$s-gray-placeholder: #667085;
$s-green: #669f2a;
$border-primary: #d0d5dd;
$border-secondary: #eaecf0;
$bg-quaternary: #eaecf0;
$s-yellow: #fac515;
$s-warn: #ffab00;
$s-orange: #ef6820;
$s-error: #d33922;
$s-moss: #669f2a;
$s-light-green: #66c61c;
$s-success: #46cb7b;
$success--200:#ABEFC6;
$success--700:#067647;
$accepted:#ECFDF3;
$s-primary: #3f8cff;
$s-accent: #3f8cff;
$s-blue: #2e90fa;
$s-indigo: #6172f3;
$s-violet: #875bf7;
$s-assist: #1d3541;
$s-inactive: #8e91a0;
$s-utility-blue-500: #2e90fa;
$s-utility-blue-400: #53b1fd;
$s-border-secondary: #eaecf0;
$header-color: #101828;
$moss-500: #669f2a;
$text-secondary-700: #344054;
$bg-quaternary: #eaecf0;
$s-bg-secondary-alt: #f9fafb;
$text-primary-900: #101828;

$text-secondary: #344054;
$s-text-secondary: #344054;
$s-text-primary: #101828;
$black: #0000;
$profile-bg: #d92d20;
$purple: #7a5af8;
$fg-brand-primary: #7f56d9;
$utility-gray-400:#98A2B3;

/*Shadows*/
$shadow-xs: rgba(16, 24, 40, 0.05);
$shadow-autocomplete: rgba(0, 0, 0, 0.25);