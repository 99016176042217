.background--icon-down-arrow {
  background: url("../../assets/images/chevron-down.svg") no-repeat right;
  background-position-x: calc(100% - 14px);
}

.background--transparent {
  background: transparent;
}

.background--line-background {
  background: url("../../assets/images/line-background.svg") no-repeat right;
}

.background--gray-background {
  background-color: #e8e8e8;
}

.background--white-background {
  background-color: #ffff;
}