.box-shadow--0-2px-5px-shadow-autocomplete {
  box-shadow: 0 2px 5px $shadow-autocomplete;
}

.box-shadow--user-input-card {
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
}

.box-shadow--shadow-xs {
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.box-shadow--shadow-sm {
  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
    0px 1px 2px 0px rgba(16, 24, 40, 0.06);
}
