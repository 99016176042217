// Cursor
.cursor--pointer {
  cursor: pointer;
}

// Outline
.outline--none {
  outline: none;
}

// Appearance
.appearance--none {
  appearance: none;
}

// Visiblity
.visibility--hidden {
  visibility: hidden;
}

.visibility--visible {
  visibility: visible;
}

// Text Decoration
.text-decoration--none {
  text-decoration: none;
}

// List Style
.list-style--none {
  list-style: none;
}

// Object Fit
.object-fit--contain {
  object-fit: contain;
}

.object-fit--scale-down {
  object-fit: scale-down;
}

.object-fit--cover {
  object-fit: cover;
}

