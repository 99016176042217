.text-align--center {
  text-align: center;
}

.text-align--left {
  text-align: left;
}

.text-align--right {
  text-align: right;
}
