.z-index--1 {
  z-index: 1;
}

.z-index--2 {
  z-index: 2;
}

.z-index--100 {
  z-index: 100;
}
