@import "font-size.scss";
@import "font-style.scss";
@import "font-weight.scss";
@import "line-height.scss";
@import "font-templates.scss";

.font-family--regular {
  font-weight: 400;
  font-family: "Inter", sans-serif;
}

.font-family--medium {
  font-weight: 500;
  font-family: "Inter", sans-serif;
}

.font-family--semibold {
  font-weight: 600;
  font-family: "Inter", sans-serif;
}
