@import "app/styles/root.scss";

/* You can add global styles to this file, and also import other style files */
body {
  margin: 0;
  height: 100vh;
}

.align-center {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.copy-right {
  font: 400 14px/20px "Inter", sans-serif;
  color: $s-gray;
  position: absolute;
  left: 5px;
  bottom: 5px;
}

.fade-in-out-animation {
  -webkit-animation: fadeinout 3s linear forwards infinite;
  animation: fadeinout 3s linear forwards infinite;
}

@-webkit-keyframes fadeinout {
  0%,
  100% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
}

@keyframes fadeinout {
  0%,
  100% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
}

.link {
  font: 600 14px/20px "Inter", sans-serif;
  line-height: 20px;
  cursor: pointer;
}

.form-field-error {
  font-size: 12px;
  font-family: "Inter", sans-serif;
  color: $s-error;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}
