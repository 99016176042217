@import "gap.scss";

//* Flex
//#region Flex
.display--flex {
  display: flex;
}

.justify-content--center {
  justify-content: center;
}

.justify-content--space-between {
  justify-content: space-between;
}

.justify-content--flex-start {
  justify-content: flex-start;
}

.justify-content--flex-end {
  justify-content: flex-end;
}

.justify-content--space-evenly {
  justify-content: space-evenly;
}

.justify-content--stretch {
  justify-content: stretch;
}

.justify-self--flex-start {
  justify-self: flex-start;
}

.justify-self--flex-end {
  justify-self: flex-end;
}

.justify-self--end {
  justify-self: end;
}

.align-items--center {
  align-items: center;
}

.align-items--flex-start {
  align-items: flex-start;
}

.align-items--flex-end {
  align-items: flex-end;
}

.align-self--stretch {
  align-self: stretch;
}

.align-self--right {
  align-self: right;
}

//#endregion

// Grid definitions
.display--grid {
  display: grid;
}

.template-columns--1 {
  grid-template-columns: repeat(1, 1fr);
}

.template-columns--2 {
  grid-template-columns: repeat(2, 1fr);
}

.template-columns--3 {
  grid-template-columns: repeat(3, 1fr);
}

.template-columns--4 {
  grid-template-columns: repeat(4, 1fr);
}

.template-columns--5 {
  grid-template-columns: repeat(5, 1fr);
}

.template-columns--6 {
  grid-template-columns: repeat(6, 1fr);
}

.template-columns--8 {
  grid-template-columns: repeat(8, 1fr);
}

.column--1 {
  grid-column: span 1;
}

.column--2 {
  grid-column: span 2;
}

.column--3 {
  grid-column: span 3;
}

// Block
.display--block {
  display: block;
}
