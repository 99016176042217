.hide-input-spin-buttons {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
    appearance: none;
  }
}

.progress-bar--4px-bg-quaternary::-webkit-progress-bar {
  border-radius: 4px;
  background-color: $bg-quaternary;
}

.progress-bar-value--4px-moss-500::-webkit-progress-value {
  border-radius: 4px;
  background: $moss-500;
}